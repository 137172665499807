<template>
  <notificationGroup group="top-right" class="notification-group">
    <notification v-slot="{notifications}">
      <v-alert
          dark
          v-for="notification in notifications"
          :key="notification.id"
          dense
          :color="notification.type"
      >
        <div class="d-flex flex-row align-center justify-start flex-nowrap">
          <div class="mr-4">
            <v-icon>{{ icon(notification.type) }}</v-icon>
          </div>
          <div>
            <h5>{{ notification.title }}
              <h5>{{ notification.text }}</h5>
            </h5>
          </div>
        </div>

        <template #close="{ toggle }">
          <v-btn @click="toggle" icon small>
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </template>

      </v-alert>
    </notification>
  </notificationGroup>
</template>

<script>
export default {
  name: "Notifications",
  methods: {
    icon(type) {
      let assoc = {success: "check-circle", warning: "alert", danger: "alert-octagon", info: "information"}
      return "mdi-" + assoc[type]
    }
  }
}
</script>

<style scoped>

.notification-group {
  position: fixed;
  top: 80px;
  right: 16px;
  z-index: 1000000;
}
</style>